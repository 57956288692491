import React from 'react';
import './Cart.css';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc, query, where, collection, getDocs, updateDoc, deleteDoc } from 'firebase/firestore';
import { db, auth } from '../firebaseConfig';
import { User, onAuthStateChanged } from 'firebase/auth';
import { FaTrash } from 'react-icons/fa';

interface CartProps {
  isOpen: boolean;
  onClose: () => void;
}

interface SkincareProduct {
  product_id: string;
  quantity: number;
}

interface ProductDetails {
  img: string;
  name: string;
  price: number;
  desc: string;
}

const Cart: React.FC<CartProps> = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const [cartItems, setCartItems] = React.useState<SkincareProduct[]>([]);
  const [productDetails, setProductDetails] = React.useState<ProductDetails[]>([]);
  
  const [user, setUser] = React.useState<User | null>(null);


  React.useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });

    return () => unsubscribe();
  }, []);


  React.useEffect(() => {
    const fetchCartItems = async () => {
      if (user) {
        const userId = user.uid;
        const userRef = doc(db, 'users', userId);
        const cartRef = collection(db, 'cart');
        const q = query(cartRef, where('user_id', '==', userRef));

        try {
          const querySnapshot = await getDocs(q);
          if (!querySnapshot.empty) {
            const cartData = querySnapshot.docs[0].data();
            if (cartData.skincare_product && Array.isArray(cartData.skincare_product)) {
              const skincareProducts: SkincareProduct[] = cartData.skincare_product.map((item: any) => ({
                product_id: item.value.product_id.path,
                quantity: item.value.quantity,
              }));

              setCartItems(skincareProducts);


              const fetchedProductDetails: ProductDetails[] = await Promise.all(
                skincareProducts.map(async (product) => {
                  const productRef = doc(db, product.product_id);
                  const productSnap = await getDoc(productRef);


                  console.log(`Fetching product for ID: ${product.product_id}`);

                  if (productSnap.exists()) {
                    const productData = productSnap.data() as ProductDetails;


                    console.log(`Fetched Product Data:`, productData);

                    return {
                      img: productData.img,
                      name: productData.name,
                      price: productData.price,
                      desc: productData.desc,
                    };
                  } else {
                    console.warn(`Product not found for ID: ${product.product_id}`);
                    return { img: '', name: '', price: 0, desc: '' };
                  }
                })
              );

              setProductDetails(fetchedProductDetails);
            } else {
              setCartItems([]);
              setProductDetails([]);
            }
          } else {
            setCartItems([]);
            setProductDetails([]);
          }
        } catch (error) {
          console.error('Error fetching cart items: ', error);
        }
      } else {

        setCartItems([]);
        setProductDetails([]);
      }
    };

    if (isOpen) {
      fetchCartItems();
    }
  }, [user, isOpen]);

  const handleQuantityChange = async (index: number, change: number) => {
    const newCartItems = [...cartItems];
    const newQuantity = newCartItems[index].quantity + change;
  
    if (newQuantity >= 0) {
      newCartItems[index].quantity = newQuantity;
      setCartItems(newCartItems);
  
      // Update the quantity in Firestore
      try {
        if (user) {
          const userId = user.uid; // Ensure userId is defined
          const userRef = doc(db, 'users', userId);
          const cartRef = collection(db, 'cart');
          const q = query(cartRef, where('user_id', '==', userRef));
          
          const querySnapshot = await getDocs(q);
  
          if (!querySnapshot.empty) {
            const cartData = querySnapshot.docs[0];
            const cartDocRef = doc(cartRef, cartData.id); // Get the document reference
  
            // Fetch the current skincare products from Firestore
            const currentCartData = cartData.data();
            const skincareProducts = currentCartData.skincare_product || [];
  
            // Update the specific product's quantity
            skincareProducts[index].value.quantity = newQuantity;
  
            // Update Firestore with the new array
            await updateDoc(cartDocRef, {
              skincare_product: skincareProducts,
            });
          }
        }
      } catch (error) {
        console.error('Error updating quantity in Firestore: ', error);
      }
    }
  };
  
  
  const handleDeleteProduct = async (product_id: string) => {
    const newCartItems = cartItems.filter(item => item.product_id !== product_id);
    setCartItems(newCartItems);

    // Remove the product from Firestore
    try {
      if (user) { // Check if user is logged in
        const userId = user.uid; // Ensure userId is defined
        const userRef = doc(db, 'users', userId);
        const cartRef = collection(db, 'cart');
        const q = query(cartRef, where('user_id', '==', userRef));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const cartData = querySnapshot.docs[0];
          const cartDocRef = doc(cartRef, cartData.id); // Get the document reference

          // Update Firestore to reflect the new cart items
          await updateDoc(cartDocRef, {
            skincare_product: newCartItems.map((item) => ({
              type: "product", // Add back the type field
              value: {
                product_id: doc(db, item.product_id), // Set the product_id as a reference
                quantity: item.quantity,
              },
            })),
          });
        }

        console.log(`Product ${product_id} removed from cart`);
        
      }
    } catch (error) {
      console.error('Error deleting product from cart: ', error);
    }
};

  
  

  const handleShopNowClick = () => {
    onClose();
    navigate('/skincare');
  };

  return (
    <>
      <div className={`cart-overlay ${isOpen ? 'open' : ''}`} onClick={onClose}></div>
      <div className={`cart ${isOpen ? 'open' : ''}`}>
        <div className="cart-header">
          <h2>Cart</h2>
          <button className="close-btn" onClick={onClose}>
            &times;
          </button>
        </div>
        <hr className="divider" />
        <div className="cart-body">
          {cartItems.length === 0 ? (
            <div className="empty-cart">
              <p className="empty-text">Your cart is empty!</p>
              <p>Add skincare products to your cart</p>
              <button className="shop-now-btn" onClick={handleShopNowClick}>
                Shop Now
              </button>
            </div>
          ) : (
            <div className="cart-items">
              {cartItems.map((item, index) => (
                <div key={index} className="cart-item">
                  <img src={productDetails[index]?.img} alt={productDetails[index]?.name} className="cart-item-img" />
                  <div className="cart-item-details">
                    <h3>{productDetails[index]?.name}</h3>
                    <p>{productDetails[index]?.desc}</p>
                    <div className="quantity-control">
                      <button onClick={() => handleQuantityChange(index, -1)}>-</button>
                      <span>{item.quantity}</span>
                      <button onClick={() => handleQuantityChange(index, 1)}>+</button>
                    </div>
                  </div>
                  <div className="cart-item-actions">
                    <button className="delete-btn" onClick={() => handleDeleteProduct(item.product_id)}>
                      <FaTrash />
                    </button>
                    <p className="product-price">${(productDetails[index]?.price * item.quantity).toFixed(2)}</p>
                  </div>

                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Cart;
