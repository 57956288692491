import React, { useState, useEffect, useRef } from 'react';
import './Navbar.css';
import logo from '../assets/Nav_Logo.png';
import accountIcon from '../assets/user.png';
import cartIcon from '../assets/cart.png';
import searchIcon from '../assets/search.png';
import closeIcon from '../assets/close.png';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { onAuthStateChanged, User } from 'firebase/auth';
import { auth, db } from '../firebaseConfig';
import defaultProfileImg from '../assets/default_profile.png';
import { collection, doc, DocumentData, getDoc, getDocs, query, where } from 'firebase/firestore';
import Cart from '../components/Cart';
import { debounce } from 'lodash';

const Navbar: React.FC = () => {

    const navigate = useNavigate();
    const [user, setUser] = useState<User | null>(null);
    const [isCartOpen, setIsCartOpen] = useState(false);

    const navigateToLogin = () => {
        navigate('/login');
    };

    const [hideAnnouncementBar, setHideAnnouncementBar] = useState(false);
    const [hideBottomNavbar, setHideBottomNavbar] = useState(false);
    const [dontShiftIcons, setShiftIcons] = useState(false);
    const [buttonForm, setButtonForm] = useState<'hamburger' | 'x'>('hamburger');
    const [isMainNavbarFixed, setIsMainNavbarFixed] = useState(false);
    const [isBottomNavbarFixed, setIsBottomNavbarFixed] = useState(false);
    const [bottomNavbarHeight, setBottomNavbarHeight] = useState(0);
    const [isScrollingUp, setIsScrollingUp] = useState(false);
    const [userPhotoURL, setUserPhotoURL] = useState<string>(defaultProfileImg);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [searchInput, setSearchInput] = useState<string>('');
    const [isSearchActive, setIsSearchActive] = useState<boolean>(false);
    const searchRef = useRef<HTMLDivElement>(null);
    const searchResultsRef = useRef<HTMLDivElement>(null);
    const [searchText, setSearchText] = useState('');
    const [skincareSearchResults, setSkincareSearchResults] = useState<DocumentData[]>([]);
    const [servicesSearchResults, setServicesSearchResults] = useState<DocumentData[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isSearchOverlayOpen, setIsSearchOverlayOpen] = useState(false);
    const [isSearchOverlayFixed, setIsSearchOverlayFixed] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            setHideAnnouncementBar(scrollPosition > 50);
            setHideBottomNavbar(scrollPosition > 50 || buttonForm === 'x');
            setIsMainNavbarFixed(scrollPosition > 50);
            setIsBottomNavbarFixed(scrollPosition > 50);
            setIsScrollingUp(scrollPosition < (window.pageYOffset || document.documentElement.scrollTop));
            setIsSearchOverlayFixed(scrollPosition > 50);
        };

        const bottomNavbar = document.getElementById('bottom-navbar');
        if (bottomNavbar) {
            setBottomNavbarHeight(bottomNavbar.offsetHeight);
        }

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleOverlayOpen = () => {
        setIsSearchOverlayOpen(true);
    };

    const handleOverlayClose = () => {
        setIsSearchOverlayOpen(false);
    };

    const handleSearchToggle = () => {
        setIsSearchOverlayOpen(!isSearchOverlayOpen);
        if (isSearchOverlayOpen) {
            clearSearch(); // Clear search when closing the overlay
        }
    };

    const handleHamburgerClick = () => {
        const newButtonForm = buttonForm === 'hamburger' ? 'x' : 'hamburger';
        setButtonForm(newButtonForm);
        setIsMobileMenuOpen(!isMobileMenuOpen);

    };

    const link = "YOUR_LINK_HERE";
    const linkStyle = {
        textDecoration: 'underline',
        color: 'white',
        size: '40px'
    };


    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
            setUser(currentUser);
            if (currentUser) {
                const userDoc = doc(db, 'users', currentUser.uid);
                const userSnap = await getDoc(userDoc);
                if (userSnap.exists()) {
                    setUserPhotoURL(userSnap.data().photoURL);
                }
            }
        });
        return () => unsubscribe();
    }, []);

    useEffect(() => {
        if (isMobileMenuOpen) {
            document.body.classList.add('no-scroll');
        } else {
            document.body.classList.remove('no-scroll');
        }
    }, [isMobileMenuOpen]);


    const navigateToProfile = () => {
        navigate('/profile');
    };

    const handleCartIconClick = () => {
        setIsCartOpen(true);
    };

    const handleCartClose = () => {
        setIsCartOpen(false);
    };

    const handleCloseMobileMenu = () => {
        setIsMobileMenuOpen(false);
        setButtonForm('hamburger');
    };

    const debouncedSearch = debounce(async (searchText: string) => {
        setIsLoading(true);
        const skincareResults = await searchSkincareCollection(searchText);
        const servicesResults = await searchServicesCollection(searchText);
        setSkincareSearchResults(skincareResults.slice(0, 3)); // Show only the first 3 results
        setServicesSearchResults(servicesResults.slice(0, 3));
        console.log(skincareSearchResults.length);
        console.log(servicesSearchResults.length);
        setIsSearchActive(true); // Move this line to ensure it's only set after results are fetched
        setIsLoading(false);
    }, 300);






    const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setSearchInput(value);
        setSearchText(value);

        if (value.trim() !== '') {
            setIsLoading(true);
            debouncedSearch(value.trim());
            setIsSearchActive(true); // Show search results when there's input
        } else {
            setIsSearchActive(false);
            clearSearch(); // Clear results and hide the search results if the input is empty
        }
    };

    const handleFocus = () => {
        if (searchText.trim() !== '') {
            setIsSearchActive(true); // Only activate search if there's input text
        }
    };


    const searchSkincareCollection = async (searchText: string) => {
        const skincareResults: DocumentData[] = [];
        const seenIds = new Set<string>();  // Set to track added product IDs
        const lowerCaseSearchText = searchText.toLowerCase();
        const searchFields = [
            'name',
            'desc',
            'information',
            'skinType',
            'productType',
            'benefits',
            'how_to_use',
            'ingredients',
            'key_ingredients',
            'faqs'
        ];

        try {
            const q = query(
                collection(db, 'skincare'),
                where('isVisible', '==', true)
            );
            const querySnapshot = await getDocs(q);

            for (const field of searchFields) {
                for (const docSnap of querySnapshot.docs) {
                    if (skincareResults.length >= 3) break; // Stop searching after 3 results

                    const data = docSnap.data();
                    const docId = docSnap.id;

                    if (seenIds.has(docId)) continue; // Skip if the product is already in the results

                    if (field === 'skinType' || field === 'productType') {
                        // Check map fields
                        const mapFieldValues = Object.values(data[field] || {});
                        if (mapFieldValues.some(value =>
                            typeof value === 'string' && value.toLowerCase().includes(lowerCaseSearchText))) {
                            skincareResults.push({ id: docSnap.id, ...data });
                            seenIds.add(docId); // Add to seen IDs set
                        }
                    } else if (field === 'key_ingredients' || field === 'faqs') {
                        // Check reference fields
                        const refDocs = await Promise.all((data[field] || []).map((ref: any) => getDoc(ref)));
                        if (refDocs.some(refDoc => refDoc.exists() &&
                            Object.values(refDoc.data()).some(value =>
                                typeof value === 'string' && value.toLowerCase().includes(lowerCaseSearchText)))) {
                            skincareResults.push({ id: docSnap.id, ...data });
                            seenIds.add(docId); // Add to seen IDs set
                        }
                    } else if (data[field] && data[field].toLowerCase().includes(lowerCaseSearchText)) {
                        // Check text fields
                        skincareResults.push({ id: docSnap.id, ...data });
                        seenIds.add(docId); // Add to seen IDs set
                    }
                }

                if (skincareResults.length >= 3) break; // Stop searching after 3 results
            }
        } catch (error) {
            console.error('Error searching skincare collection:', error);
        }

        return skincareResults;
    };

    const searchServicesCollection = async (searchText: string) => {
        const servicesResults: DocumentData[] = [];
        const seenIds = new Set<string>();  // Set to track added product IDs
        const lowerCaseSearchText = searchText.toLowerCase();
        const searchFields = [
            'name',
            'desc',
            'general_service',
            'types',
            'group',
            'tagline',
            'what_is',
            'benefits',
            'key_info',
            'faqs'
        ];

        try {
            const q = query(
                collection(db, 'services'),
                where('isVisible', '==', true)
            );
            const querySnapshot = await getDocs(q);

            for (const field of searchFields) {
                for (const docSnap of querySnapshot.docs) {
                    if (servicesResults.length >= 3) break; // Stop searching after 3 results

                    const data = docSnap.data();
                    const docId = docSnap.id;

                    if (seenIds.has(docId)) continue; // Skip if the product is already in the results
                    if (field === 'general_service' || field === 'types') {
                        const refDocs = await Promise.all((data[field] || []).map((ref: any) => getDoc(ref)));
                        if (refDocs.some(refDoc => refDoc.exists() &&
                            Object.values(refDoc.data()).some(value =>
                                typeof value === 'string' && value.toLowerCase().includes(lowerCaseSearchText)))) {
                            servicesResults.push({ id: docSnap.id, ...data });
                            seenIds.add(docId); // Add to seen IDs set
                        }
                    }
                    else if (field === 'tagline') {
                        // Check map fields
                        const mapFieldValues = Object.values(data[field] || {});
                        if (mapFieldValues.some(value =>
                            typeof value === 'string' && value.toLowerCase().includes(lowerCaseSearchText))) {
                            servicesResults.push({ id: docSnap.id, ...data });
                            seenIds.add(docId); // Add to seen IDs set
                        }
                    }
                    else if (field === 'what_is') {
                        // Check map fields
                        const mapFieldValues = Object.values(data[field] || {});
                        if (mapFieldValues.some(value =>
                            typeof value === 'string' && value.toLowerCase().includes(lowerCaseSearchText))) {
                            servicesResults.push({ id: docSnap.id, ...data });
                            seenIds.add(docId); // Add to seen IDs set
                        }
                    } else if (field === 'benefits' || field === 'key_info' || field === 'faqs') {
                        // Check reference fields
                        const refDocs = await Promise.all((data[field] || []).map((ref: any) => getDoc(ref)));
                        if (refDocs.some(refDoc => refDoc.exists() &&
                            Object.values(refDoc.data()).some(value =>
                                typeof value === 'string' && value.toLowerCase().includes(lowerCaseSearchText)))) {
                            servicesResults.push({ id: docSnap.id, ...data });
                            seenIds.add(docId); // Add to seen IDs set
                        }
                    } else if (data[field] && data[field].toLowerCase().includes(lowerCaseSearchText)) {
                        // Check text fields
                        servicesResults.push({ id: docSnap.id, ...data });
                        seenIds.add(docId); // Add to seen IDs set
                    }
                }

                if (servicesResults.length >= 3) break; // Stop searching after 3 results
            }
        } catch (error) {
            console.error('Error searching skincare collection:', error);
        }

        return servicesResults;
    };





    const clearSearch = () => {
        setSearchInput('');
        setSearchText('');
        setSkincareSearchResults([]);
        setServicesSearchResults([]);
        setIsLoading(false);
        setIsSearchActive(false);
        debouncedSearch.cancel();
    };


    const handleSearchClick = () => {
        setIsSearchActive(false);
        setIsSearchOverlayOpen(false);
        if (searchText.trim() !== '') {
            navigate(`/search-results?query=${encodeURIComponent(searchText)}`);
        }
    };



    const handleViewAllClick = () => {
        setIsSearchActive(false);
        handleSearchClick();
        clearSearch();
    };

    const handleBookNow = () => {
        navigate('/book-an-appointment');
    }



    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                searchRef.current && !searchRef.current.contains(event.target as Node) &&
                searchResultsRef.current && !searchResultsRef.current.contains(event.target as Node)
            ) {
                setIsSearchActive(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    const handleSearchResultsClick = (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
    };


    // Handles key down event for 'Enter' key
    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        setIsSearchActive(false);
        if (e.key === 'Enter' && searchText.trim() !== '') {
            handleSearchClick();
        }
    };

    const generateSlug = (name: string) => {
        return name
            .toLowerCase()
            .replace(/[^a-z0-9\s]/g, '') // Remove special characters
            .replace(/\s+/g, '-');       // Replace spaces with hyphens
    };

    const navigateToResultPageSkincare = (name: string) => {
        setIsSearchOverlayOpen(false);
        setIsSearchActive(false);
        navigate(`/products/${generateSlug(name)}`)
    }

    const navigateToResultPageServices = (name: string) => {
        setIsSearchOverlayOpen(false);
        setIsSearchActive(false);
        navigate(`/services/${generateSlug(name)}`)
    }

    return (
        <div className="navbar-wrapper">
            <div className={`announcement-bar ${hideAnnouncementBar ? 'hide-announcement-bar' : ''}`}>
                <p><a >Welcome to the New QD Skinnovations Website!</a></p>
            </div>
            <nav id="main-navbar" className={`navbar main-navbar ${isBottomNavbarFixed && buttonForm === 'x' ? 'fixed-main-navbar' : ''} ${hideBottomNavbar && buttonForm === 'hamburger' ? 'fixed-main-navbar' : ''}`}>
                <div className="navbar-container">
                    {hideBottomNavbar && (
                        <div className="hamburger-menu" onClick={handleHamburgerClick}>
                            {buttonForm === 'hamburger' ? (
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                    <path d="M0 0h24v24H0z" fill="none" />
                                    <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" />
                                </svg>
                            ) : (
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                    <path d="M0 0h24v24H0z" fill="none" />
                                    <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                                </svg>
                            )}
                        </div>
                    )}
                    <div className="hamburger-menu-mobile" onClick={handleHamburgerClick}>
                        {buttonForm === 'hamburger' ? (
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                <path d="M0 0h24v24H0z" fill="none" />
                                <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" />
                            </svg>
                        ) : (
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                <path d="M0 0h24v24H0z" fill="none" />
                                <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                            </svg>
                        )}
                    </div>
                    <Link to="/" className={`logo-container ${hideBottomNavbar ? 'logo-slide-right' : ''} logo-container-desktop`}>
                        <img src={logo} className="navbar-logo" alt="logo" />
                    </Link>
                    <Link to="/" className='logo-container logo-slide-right logo-container-mobile'>
                        <img src={logo} className="navbar-logo" alt="logo" />
                    </Link>
                    <div ref={searchRef} className={`search-bar search-bar-container search-container ${hideBottomNavbar ? (isScrollingUp ? 'slide-left' : 'slide-right') : ''}`}>
                        <div className={`search-bar ${isSearchActive ? 'active' : ''}`}>
                            <img
                                src={searchIcon}
                                alt="Search"
                                className="search-icon"
                                onClick={handleSearchClick}
                            />
                            <input
                                type="text"
                                placeholder="Search"
                                value={searchInput}
                                onChange={handleSearchInputChange}
                                onFocus={handleFocus}
                                onKeyDown={handleKeyDown}
                            />
                            {searchInput && (
                                <img
                                    src={closeIcon}
                                    alt="Clear"
                                    className="clear-icon"
                                    onClick={clearSearch}
                                />
                            )}

                        </div>
                        {isSearchActive || isLoading ? (
                            <div className="search-results" ref={searchResultsRef} onClick={handleSearchResultsClick}>
                                {isLoading ? (
                                    <div className="loading-spinner-container">
                                        <div className="loading-spinner"></div>
                                    </div>
                                ) : (
                                    <div>
                                        {skincareSearchResults.length > 0 || servicesSearchResults.length > 0 ? (
                                            <div>
                                                <h3 className='result-label'>Skincare</h3>
                                                <div className="search-results-grid-nav">
                                                    {skincareSearchResults.map((skincareResult) => (
                                                        <div key={skincareResult.id} className="search-result-card-nav">
                                                            <Link to={`/products/${generateSlug(skincareResult.name)}`} onClick={clearSearch}>
                                                                <img src={skincareResult.img} alt={skincareResult.name} className="search-result-img-nav" />
                                                                <div className="search-result-name-nav">{skincareResult.name}</div>
                                                                <div className="search-result-price-nav">${skincareResult.price.toFixed(2)}</div>
                                                            </Link>
                                                        </div>
                                                    ))}
                                                </div>
                                                <h3 className='result-label'>Services</h3>
                                                <div className="search-results-grid-nav">
                                                    {servicesSearchResults.map((serviceResults) => (
                                                        <div key={serviceResults.id} className="search-result-card-nav">
                                                            <Link to={`/services/${generateSlug(serviceResults.name)}`} onClick={clearSearch}>
                                                                <img src={serviceResults.img} alt={serviceResults.name} className="search-result-img-nav" />
                                                                <div className="search-result-name-nav">{serviceResults.name}</div>
                                                            </Link>
                                                        </div>
                                                    ))}
                                                </div>
                                                <div className="view-all">
                                                    <button onClick={handleViewAllClick}>View All</button>
                                                </div>
                                            </div>
                                        ) : (
                                            <div>No results found</div>
                                        )}
                                    </div>
                                )}
                            </div>
                        ) : null}


                    </div>

                    <div className="user-cart-icons">
                        <div className="user-icon">
                            <button className='searchToggle'>
                                <img
                                    src={isSearchOverlayOpen ? closeIcon : searchIcon}
                                    alt="Search"
                                    width="20" height="20"
                                    onClick={handleSearchToggle}
                                />
                            </button>
                        </div>
                        <a>
                            <button className="book-now-btn" onClick={() =>handleBookNow()}>Book Now!</button>
                        </a>
                        <div className="user-icon">
                            {user ? (
                                <button className='accountBtn' onClick={navigateToProfile}>
                                    <img src={userPhotoURL} alt="User Profile" className="user-photo" />
                                </button>
                            ) : (
                                <button className='accountBtn' onClick={navigateToLogin}>
                                    <img src={accountIcon} alt="Account" width="24" height="24" />
                                </button>
                            )}
                        </div>
                        <div className="cart-icon">
                            <button className='cartBtn' onClick={handleCartIconClick}>
                                <img src={cartIcon} alt="Cart" width="24" height="24" />
                            </button>
                        </div>
                    </div>
                </div>
            </nav>
            {isSearchOverlayOpen && (
                <div
                    className={`overlay ${isSearchOverlayFixed ? 'fixed-overlay' : ''}`}
                    style={{ display: isSearchOverlayOpen ? 'block' : 'none' }}
                >
                    <div className="search-overlay-content">
                        <div className="search-bar-overlay">
                            <div className='search-bar-overlay'>
                                <input
                                    type="text"
                                    placeholder="Search"
                                    value={searchInput}
                                    onChange={handleSearchInputChange}
                                    onFocus={handleFocus}
                                    onKeyDown={handleKeyDown}
                                />
                                {searchInput && (
                                    <img
                                        src={closeIcon}
                                        alt="Clear"
                                        className="clear-icon-ov"
                                        onClick={clearSearch}
                                    />
                                )}
                                <img
                                    src={searchIcon}
                                    alt="Search"
                                    className='search-icon-ov'
                                    onClick={handleSearchClick}
                                />
                            </div>

                            {/* Display search results as a list */}
                            {(skincareSearchResults.length > 0 || servicesSearchResults.length > 0) ? (
                                <ul className="search-results-list">
                                    {/* Only show the "Skincare" label and its results if there are skincare results */}
                                    {skincareSearchResults.length > 0 && (
                                        <>
                                            <h3 className='result-label'>Skincare</h3>
                                            {skincareSearchResults.map((skincareResult) => (
                                                <li key={skincareResult.id} onClick={() => navigateToResultPageSkincare(skincareResult.name)}>
                                                    <img src={skincareResult.img} alt={skincareResult.name} className="search-result-img" />
                                                    <div className="search-result-name">{skincareResult.name}</div>
                                                    <div className="search-result-price">${skincareResult.price.toFixed(2)}</div>
                                                </li>
                                            ))}
                                        </>
                                    )}

                                    {/* Only show the "Services" label and its results if there are service results */}
                                    {servicesSearchResults.length > 0 && (
                                        <>
                                            <h3 className='result-label'>Services</h3>
                                            {servicesSearchResults.map((serviceResult) => (
                                                <li key={serviceResult.id} onClick={() => navigateToResultPageServices(serviceResult.name)}>
                                                    <img src={serviceResult.img} alt={serviceResult.name} className="search-result-img" />
                                                    <div className="search-result-name">{serviceResult.name}</div>
                                                </li>
                                            ))}
                                        </>
                                    )}

                                    {/* Show "View All" button if there are any results */}
                                    {(skincareSearchResults.length > 0 || servicesSearchResults.length > 0) && (
                                        <div className="view-all">
                                            <button onClick={handleViewAllClick}>View All</button>
                                        </div>
                                    )}
                                </ul>
                            ) : (
                                <div>No results found</div>
                            )}

                        </div>
                    </div>
                </div>
            )}

            <nav id="bottom-navbar" className={`navbar bottom-navbar ${hideBottomNavbar && buttonForm === 'hamburger' ? 'hide-bottom-navbar' : ''} ${isBottomNavbarFixed && buttonForm === 'x' ? 'fixed-bottom-navbar'
                : ''}`}>
                <div className="navbar-container">
                    <div className="navbar-links">
                        <Link to="/medspa" onClick={handleCloseMobileMenu}>Shop MedSpa Services</Link>
                        <Link to="/skincare" onClick={handleCloseMobileMenu}>Shop Skincare</Link>
                        <Link to="/reviews" onClick={handleCloseMobileMenu}>Reviews</Link>
                        <Link to="/gallery" onClick={handleCloseMobileMenu}>Gallery</Link>
                        <Link to="/membership" onClick={handleCloseMobileMenu}>Join VIP Membership</Link>
                        <Link to="/about-us" onClick={handleCloseMobileMenu}>About Us</Link>
                        <Link to="/contact-us" onClick={handleCloseMobileMenu}>Contact Us</Link>
                        <Link to="/events" onClick={handleCloseMobileMenu}>Events</Link>
                    </div>
                </div>
            </nav>
            <nav id="mobile-bottom-navbar" className={`mobile-bottom-navbar ${isMobileMenuOpen ? 'open' : ''}`}>
                <div className="mobile-navbar-container">
                    <div className="close-icon" onClick={handleCloseMobileMenu}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                            <path d="M0 0h24v24H0z" fill="none" />
                            <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                        </svg>
                    </div>
                    <div className="mobile-navbar-links">
                        <Link to="/medspa" onClick={handleCloseMobileMenu}>Shop MedSpa Services</Link>
                        <Link to="/skincare" onClick={handleCloseMobileMenu}>Shop Skincare</Link>
                        <Link to="/reviews" onClick={handleCloseMobileMenu}>Reviews</Link>
                        <Link to="/gallery" onClick={handleCloseMobileMenu}>Gallery</Link>
                        <Link to="/membership" onClick={handleCloseMobileMenu}>Join VIP Membership</Link>
                        <Link to="/about-us" onClick={handleCloseMobileMenu}>About Us</Link>
                        <Link to="/contact-us" onClick={handleCloseMobileMenu}>Contact Us</Link>
                        <Link to="/events" onClick={handleCloseMobileMenu}>Events</Link>
                    </div>
                </div>
            </nav>
            <Cart isOpen={isCartOpen} onClose={handleCartClose} />
        </div>
    );
};

export default Navbar;
